(function ({ jQuery: $ }) {
  /*! ===== 여기에 스크립트를 작성해 주세요. ===== */
  const $container = $('#mainHero');

  $container.find('.hero_slider').slick({
    appendDots: $container.find('.hero_nav'),
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    nextArrow: `<a class="hero_move hero_move_next" href="#" role="button"><img src="/img/main/next_arrow.png" /></a>`,
    pauseOnDotsHover: true,
    prevArrow: `<a class="hero_move hero_move_previous" href="#" role="button"><img src="/img/main/prev_arrow.png" /></a>`,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
  });
})(
  (function (jQuery) {
    requireModule('jQuery', typeof jQuery === 'function');
    requireModule('slick', jQuery.isFunction(jQuery.fn.slick));

    function requireModule(name, predicate) {
      if (!predicate) {
        throw new Error(
          `Unable to find ${name} module. Please make sure it is loaded before this script.`
        );
      }
    }

    return { jQuery };
  })(window.jQuery)
);
